<template>
    <div class="d-flex content-center min-vh-100 bg-dark" :class="{ 'c-dark-theme': $store.state.darkMode, animated: false, fadeIn: true }">
        <BContainer>
            <BRow class="justify-content-center">
                <BCol md="8">
                    <BCard class="text-left" footer-tag="footer" footer-bg-variant="light">
                        <div slot="header">
                            <strong class="text-white">비밀번호 재설정</strong>
                            <div class="card-header-actions">
                                <small class="text-muted"><strong class="alert-danger">{{userId}}</strong> 아이디의 비밀번호를 재설정 합니다.</small>
                            </div>
                        </div>
  
                        <BRow>
                            <BCol>
                                <BFormInput class="mt-3" v-model="currPwd" type="password" placeholder="현재 비밀번호를 입력하세요" />
                                <BFormInput class="mt-3" v-model="newPwd" :state="validPwdState()" type="password" placeholder="비밀번호는 영문.숫자.특수문자를 포함해야 하며 최소8자 이상입니다" :disabled="!isValidUser" />
                                <BFormInput class="mt-2" v-model="rePwd" :state="validPwdConfirm()" type="password" placeholder="비밀번호를 다시 입력해주새요" :disabled="!isValidUser" />
                            </BCol>
                        </BRow>
  
                        <BRow class="mt-3" align-h="between">
                            <BCol>
                                <BButton to="/login">로그인</BButton>
                            </BCol>
                            <BCol class="text-right">
                                <BButton variant="danger" @click="setUserPwd" :disabled="!isValidUser || !isPwdChecked">비밀번호 변경</BButton>
                            </BCol>
                        </BRow>
                    </BCard>
                </BCol>
            </BRow>
        </BContainer>
    </div>
  </template>
  
  <script>
  import { apiCall, modalWarn, modalSuccess } from '../../common/utils';
  
  export default {
    name: 'PasswordReset',
    props: ['userId'],
    data() {
      return {
        newPwd: '',
        currPwd: '',
        rePwd: '',
        isValidUser: false,
        isPwdChecked: false,
        pwdRegexp: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/, // 최소8자 영문,숫자,특수문자 조합
      };
    },
    async created() {
      try {
        console.log("-------- created: PasswordReset From ----- userId: " + this.userId);
        console.log("-------- created: PasswordReset From ----- userId: " + JSON.stringify(this.$route.params));
        if (this.userId) this.isValidUser = true;
      } catch (err) {
        console.log(err);
        await modalWarn(this.$bvModal, 'ERROR: ' + err.message, '에러');
        return;
      }
    },
    methods: {
      validPwdState() {
        let ret = false;
        if (this.newPwd.length > 7) {
          if (this.pwdRegexp.test(this.newPwd)) ret = true;
        }
        return ret;
      },
      validPwdConfirm() {
        let ret = false;
        const p1 = this.newPwd;
        const p2 = this.rePwd;
        if (p2.length > 7 && p1 === p2) {
          ret = true;
          this.isPwdChecked = true;
        } else {
          this.isPwdChecked = false;
        }
        return ret;
      },
      async setUserPwd() {
        console.log("setUserPwd ---> ");
        try {
          const param = {
            userId: this.userId,
            currPwd: btoa(this.currPwd),
            newPwd: btoa(this.newPwd),
            rePwd: btoa(this.rePwd)
          };
          const r = await apiCall('post', `/api/auth/pwd-change-on-login`, param);
          if (r.result) {
            await modalSuccess(this.$bvModal, "비밀번호가 변경 되었습니다", "Success");
            await this.$router.push({ path: '/login' });
          } else {
            await modalWarn(this.$bvModal, '비밀번호 변경 실패: ' + r.message, 'Error:' + r.code);
          }
        } catch (err) {
          console.log(err);
          await modalWarn(this.$bvModal, 'ERROR: ' + err.message, '에러');
        }
      }
    }
  }
  </script>
  